import ordersApi from "../../api/orders-api";
import router from "../../router";
import store from "../../store";

export default {
  namespaced: true,
  state: {
    listOrders: [],
    order: {},
    totalListOrders: 0,
    productsUnavailable: [],
    showModalStockAvailable: false,
    fecha: "",
    comment: "",
  },
  mutations: {
    setListOrders(state, payload) {
      state.listOrders = payload.data.items;
    },
    setOrder(state, payload) {
      state.order = payload;
    },
    setTotalListOrders(state, payload) {
      state.totalListOrders = payload.data.total;
    },
    setShowModalStockAvailable(state, payload) {
      state.showModalStockAvailable = payload;
      console.log(state.showModalStockAvailable);
    },
    setProductsUnavailable(state, payload) {
      state.productsUnavailable = payload;
    },
    setFecha(state, payload) {
      state.fecha = payload;
    },
    setComment(state, payload) {
      state.comment = payload;
    },
  },
  actions: {
    async searchOrders(
      context,
      {
        limit = 10,
        offset = 0,
        sort = "",
        idClient = 0,
        fecha_ini = "",
        fecha_fin = "",
      }
    ) {
      const res = await ordersApi.getListOrdersByClient(
        limit,
        offset,
        sort,
        idClient,
        fecha_ini,
        fecha_fin
      );
      console.log(res.data.total);
      context.commit("setListOrders", res);
      context.commit("setTotalListOrders", res);
    },
    async getOrder(context, idOrder) {
      const res = await ordersApi.getOrder(idOrder, 0);
      context.commit("setOrder", res.data[0]);
    },
    /*     async checkProducts(
      context,
      { comment, fecha, setShowModalStockAvailable }
    ) {
      await ordersApi.checkProducts(comment, fecha).then(
        // eslint-disable-next-line no-unused-vars
        async (response) => {
          console.log("checkProducts:", response.data.notAvailables);

          if (response.data.notAvailables.length === 0) {
            //CREA ORDER
            await ordersApi.createOrder(comment, fecha).then(
              // eslint-disable-next-line no-unused-vars
              (response) => {
                ordersApi.createFlujo().then(
                  // eslint-disable-next-line no-unused-vars
                  (response) => {
                    localStorage.removeItem("cart");
                    store.dispatch("carts/getCartOrCreate", {
                      username: "",
                      idClient: "",
                    });
                    router.push({ name: "orders" });
                  },
                  // eslint-disable-next-line no-unused-vars
                  (error) => {
                    console.log("ERROR FLUJO: ", error);
                    //TODO notificación error pedido
                  }
                );
              },
              // eslint-disable-next-line no-unused-vars
              (error) => {
                console.log("ERROR ORDER: ", error);
                //TODO notificación error pedido
              }
            );
          } else {
            context.commit("setComment", comment);
            context.commit("setFecha", fecha);
            context.commit(
              "setProductsUnavailable",
              response.data.notAvailables
            );
            setShowModalStockAvailable(true);
          }
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log("ERROR checkProducts: ", error);
          //TODO notificación error pedido
        }
      );
    }, */
    async createOrder(context, { comment, fecha, setShowModalStockAvailable }) {
      await ordersApi.createOrder(comment, fecha).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          ordersApi.createFlujo().then(
            // eslint-disable-next-line no-unused-vars
            (response) => {
              localStorage.removeItem("cart");
              store.dispatch("carts/getCartOrCreate", {
                username: "",
                idClient: "",
              });
              router.push({ name: "orders" });
            },
            // eslint-disable-next-line no-unused-vars
            (error) => {
              console.log("ERROR FLUJO: ", error);
              //TODO notificación error pedido
            }
          );
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log("ERROR ORDER: ", error);
          //TODO notificación error pedido
        }
      );
    },
  },
  getters: {
    getListOrders: (state) => state.listOrders,
    getOrder: (state) => state.order,
    getTotalListOrders: (state) => state.totalListOrders,
    getShowModalStockAvailable: (state) => state.showModalStockAvailable,
    getProductsUnavailable: (state) => state.productsUnavailable,
  },
};
