import axios from "axios";
import { config } from "../shared/app.config";
import store from "@/store";

const RESOURCE_NAME = "/pedidos";
const RESOURCE_NAME_SINGULAR = "/pedido";
const RESOURCE_NAME_EDI = "/edi";

export default {
  getListOrdersByClient(
    limit,
    offset,
    sort,
    idClient = 0,
    fecha_ini,
    fecha_fin
  ) {
    const params = {
      limit,
      offset,
    };

    if (fecha_ini) {
      params.fecha_ini = fecha_ini;
    }
    if (fecha_fin) {
      params.fecha_fin = fecha_fin;
    }
    if (sort) {
      params.sort = sort;
    }

    const username = store.getters["auth/getCurrentUsername"];

    const user = store.getters["auth/getUser"];

    console.log("params: ", params);

    if (user.rol_id === 3) {
      //Obtenemos los pedidos de ese usuario como cliente y "admin" como comercial
      return axios.get(
        `${config.webApiBase + RESOURCE_NAME}/comercial/admin/cliente/${
          user.usuario
        }`,
        { params }
      );
    }
    if (idClient === 0) {
      return axios.get(
        `${config.webApiBase + RESOURCE_NAME}/comercial/${username}`,
        { params }
      );
    } else {
      return axios.get(
        `${config.webApiBase +
          RESOURCE_NAME}/comercial/${username}/cliente/${idClient}`,
        { params }
      );
    }
  },
  getOrder(idOrder) {
    return axios.get(`${`${config.webApiBase + RESOURCE_NAME}/${idOrder}`}`);
  },
  checkProducts(comment, fecha) {
    const carrito = store.getters["carts/getCart"];

    const newLineas = carrito.lineas.map((rec) => {
      const cantped = (rec.cantidad * rec.metrosCaja).toFixed(6);

      const totalCajas = rec.cantidad;
      return {
        cajasTotales: totalCajas,
        picking: rec.pickingLine,
        refean: rec.idArticulo,
        cantped: cantped,
        umedida: rec.unidades,
        cajasPalet: rec.cajasPalet,
        metrosCaja: rec.metrosCaja,
      };
    });

    var params = {
      comprador: carrito.lineas[0].idCliente,
      vendedor: carrito.lineas[0].idComercial,
      lines: newLineas,
      comment: {
        tema: "AAI",
        texto1: comment,
      },
      fechav: fecha,
    };

    return axios.post(`${`${config.webApiBase + "/checkproducts"}`}`, params);
  },
  createOrder(comment, fecha) {
    const carrito = store.getters["carts/getCart"];

    const user = store.getters["auth/getUser"];

    console.log(user);

    /*  var cantped = (
      (pales * itemToEdit.cajasPalet + cajas) *
      itemToEdit.metrosCaja
    ).toFixed(2);
    var totalCajas = pales * itemToEdit.cajasPalet + cajas; */

    const newLineas = carrito.lineas.map((rec) => {
      const cantped = (rec.cantidad * rec.metrosCaja).toFixed(6);

      const totalCajas = rec.cantidad;
      return {
        cajasTotales: totalCajas,
        picking: rec.pickingLine,
        refean: rec.idArticulo,
        cantped: cantped,
        umedida: rec.unidades,
        cajasPalet: rec.cajasPalet,
        metrosCaja: rec.metrosCaja,
      };
    });

    var params = {
      comprador: carrito.lineas[0].idCliente,
      vendedor: carrito.lineas[0].idComercial,
      correo: user.nombre,
      lines: newLineas,
      comment: {
        tema: "AAI",
        texto1: comment,
      },
      fechav: fecha,
    };

    return axios.post(
      `${`${config.webApiBase + RESOURCE_NAME_SINGULAR}`}`,
      params
    );
  },
  createFlujo() {
    return axios.get(
      `${`${config.webApiBase + RESOURCE_NAME_EDI}/flujo/todo`}`
    );
  },
};
