import productsApi from "../../api/products-api";

export default {
  namespaced: true,
  state: {
    listProducts: [],
    product: {},
    productAdd: {},
    lotes: [],
    showModalAdd: false,
    totalListProducts: 0,
  },
  mutations: {
    setListProducts(state, payload) {
      state.listProducts = payload.data.items;
    },
    setProduct(state, payload) {
      state.product = payload;
    },
    setLotes(state, payload) {
      state.lotes = payload;
    },
    setProductAdd(state, payload) {
      state.productAdd = payload;
    },
    setTotalListProducts(state, payload) {
      state.totalListProducts = payload.data.total;
    },
    setShowModalAdd(state, payload) {
      state.showModalAdd = payload;
    },
  },
  actions: {
    async searchProducts(
      context,
      { search = "", limit = 10, offset = 0, sort = "", family = "", size = "" }
    ) {
      const res = await productsApi.getListProducts(
        search,
        limit,
        offset,
        sort,
        family,
        size
      );
      context.commit("setListProducts", res);
      context.commit("setTotalListProducts", res);
    },
    async getProduct(context, idProduct) {
      console.log("idProduct:", idProduct);
      const res = await productsApi.getProduct(idProduct, 0);
      context.commit("setProduct", res.data[0]);
    },
    async getLotes(context, idProduct) {
      const res = await productsApi.getProduct(idProduct, 1);
      res.data.forEach((item, index, object) => {
        // console.log("item ", item.Lote);
        if (res.data.length > 1 && item.Lote === "0") {
          object.splice(index, 1);
        }
      });
      context.commit("setLotes", res.data);
      context.commit("setProductAdd", res.data[0]);
    },
    async getProductAdd(context, idProduct) {
      const res = await productsApi.getProduct(idProduct, 1);
      context.commit("setProductAdd", res.data[0]);
    },
  },
  getters: {
    getListProducts: (state) => state.listProducts,
    getProduct: (state) => state.product,
    getProductAdd: (state) => state.productAdd,
    getLotes: (state) => state.lotes,
    getTotalListProducts: (state) => state.totalListProducts,
    getShowModalAdd: (state) => state.showModalAdd,
  },
};
