import axios from "axios";
import { config } from "../shared/app.config";
import store from "@/store";

const RESOURCE_NAME = "/carritos";
const RESOURCE_NAME_SINGULAR = "/carrito";

export default {
  getCartByClient(username = "", idClient = "") {
    if (!idClient) {
      const client = store.getters["customers/getCurrentCustomer"];
      if (client) {
        idClient = client.IDEMPRESA;
      }
    }
    if (!username) {
      username = store.getters["auth/getCurrentUsername"];
    }

    const user = store.getters["auth/getUser"];
    console.log("IDUSER: ", user.rol_id);

    if (user.rol_id === 3) {
      //Obtenemos el carrito de ese usuario como cliente y "admin" como comercial
      return axios.get(
        `${config.webApiBase + RESOURCE_NAME}/comercial/admin/cliente/${
          user.usuario
        }`
      );
    }

    return axios.get(
      `${config.webApiBase +
        RESOURCE_NAME}/comercial/${username}/cliente/${idClient}`
    );
  },
  createEmptyCart(username = "", idClient = "") {
    if (!idClient) {
      const client = store.getters["customers/getCurrentCustomer"];
      if (client) {
        idClient = client.IDEMPRESA;
      }
    }
    if (!username) {
      username = store.getters["auth/getCurrentUsername"];
    }

    const user = store.getters["auth/getUser"];

    var params = {
      comprador: idClient,
      vendedor: username,
    };

    if (user.rol_id === 3) {
      //Creamos el carrito de ese usuario como cliente y "admin" como comercial
      params = {
        comprador: user.usuario,
        vendedor: "admin",
      };
    }

    return axios.post(`${config.webApiBase + RESOURCE_NAME_SINGULAR}`, params);
  },
  addProduct(
    refean,
    cantped,
    umedida,
    picking,
    cajasTotales,
    cajasPalet,
    metrosCaja,
    peso
  ) {
    const currentCart = store.getters["carts/getIdCurrentCart"];

    const params = {
      refean,
      cantped,
      umedida,
      picking,
      cajasTotales,
      cajasPalet,
      metrosCaja,
      peso,
    };

    console.log(params);

    return axios.put(
      `${config.webApiBase + RESOURCE_NAME_SINGULAR}/${currentCart}/add`,
      params
    );
  },
  removeLine(idLinea) {
    return axios.delete(
      `${config.webApiBase + RESOURCE_NAME_SINGULAR}/linea/${idLinea}`
    );
  },
  updateLine(
    idCarrito,
    idLinea,
    refean,
    cantped,
    umedida,
    picking,
    cajasTotales,
    cajasPalet,
    metrosCaja,
    peso
  ) {
    const params = {
      refean,
      cantped,
      umedida,
      picking,
      cajasTotales,
      cajasPalet,
      metrosCaja,
      peso,
    };
    return axios.post(
      `${config.webApiBase +
        RESOURCE_NAME_SINGULAR}/${idCarrito}/edit/${idLinea}`,
      params
    );
  },
};
