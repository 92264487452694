import cartsApi from "../../api/carts-api";

export default {
  namespaced: true,
  state: {
    cart: {},
    successAdd: false,
    errorAdd: false,
  },
  mutations: {
    recoverCart(state) {
      const recoveredCart = JSON.parse(localStorage.getItem("cart"));
      if (!recoveredCart) {
        this.dispatch("carts/getCartOrCreate", { username: "", idClient: "" });
      } else {
        state.cart = recoveredCart;
      }
    },
    setCart(state, payload) {
      localStorage.setItem("cart", JSON.stringify(payload));
      state.cart = payload;
    },
    setSuccessAdd(state, payload) {
      state.successAdd = payload;
    },
    setErrorAdd(state, payload) {
      state.errorAdd = payload;
    },
    removeLocalCart(state) {
      localStorage.removeItem("cart");
      state.cart = {};
    },
  },
  actions: {
    async getCartOrCreate(context, { username = "", idClient = "" }) {
      const res = await cartsApi.getCartByClient(username, idClient);

      if (Object.keys(res.data).length) {
        context.commit("setCart", res.data);
      } else {
        context.dispatch("createEmptyCart", { username, idClient });
      }
    },
    async createEmptyCart(context, { username = "", idClient = "" }) {
      await cartsApi.createEmptyCart(username, idClient);
      context.dispatch("getCartOrCreate", { username, idClient });
    },
    async removeLine(context, idLinea) {
      await cartsApi.removeLine(idLinea);
      location.reload();
    },
    async updateLine(
      context,
      {
        idCarrito = "",
        idLinea = "",
        refean = "",
        cantped = "",
        umedida = "",
        picking = "",
        cajasTotales = "",
        cajasPalet = "",
        metrosCaja = "",
        peso = "",
      }
    ) {
      await cartsApi.updateLine(
        idCarrito,
        idLinea,
        refean,
        cantped,
        umedida,
        picking,
        cajasTotales,
        cajasPalet,
        metrosCaja,
        peso
      );
      location.reload();
    },
    async addProduct(
      context,
      {
        refean = "",
        cantped = "",
        umedida = "",
        picking = "",
        cajasTotales = "",
        cajasPalet = "",
        metrosCaja = "",
        peso = "",
      }
    ) {
      await cartsApi
        .addProduct(
          refean,
          cantped,
          umedida,
          picking,
          cajasTotales,
          cajasPalet,
          metrosCaja,
          peso
        )
        .then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            context.dispatch("getCartOrCreate", { username: "", idClient: "" });
            context.commit("setSuccessAdd", true);
          },
          // eslint-disable-next-line no-unused-vars
          (error) => {
            context.commit("setErrorAdd", true);
          }
        );
    },
  },
  getters: {
    getCart: (state) => state.cart,
    getIdCurrentCart: (state) => state.cart.idCarrito,
    getSuccessAdd: (state) => state.successAdd,
    getErrorAdd: (state) => state.errorAdd,
  },
};
