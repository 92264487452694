import reportsApi from "../../api/reports-api";
import router from "../../router";
import store from "../../store";

export default {
  namespaced: true,
  state: {
    listReports: [],
    report: {},
    totalListReports: 0,
    fecha: "",
    comment: "",
    successAdd: false,
    errorAdd: false,
    showModalAdd: false,
  },
  mutations: {
    setListReports(state, payload) {
      state.listReports = payload.data.items;
    },
    setReport(state, payload) {
      state.report = payload;
    },
    setTotalListReports(state, payload) {
      state.totalListReports = payload.data.total;
    },
    setSuccessAdd(state, payload) {
      state.successAdd = payload;
    },
    setErrorAdd(state, payload) {
      state.errorAdd = payload;
    },
    setShowModalAdd(state, payload) {
      state.showModalAdd = payload;
    },
  },
  actions: {
    async searchReports(context, { limit = 10, offset = 0, sort = "" }) {
      const res = await reportsApi.getListReports(limit, offset, sort);
      context.commit("setListReports", res);
      context.commit("setTotalListReports", res);
    },
    async getReport(context, idReport) {
      const res = await reportsApi.getReport(idReport);

      context.commit("setReport", res.data.URLInforme);
    },

    async createReport(context, idReport) {
      await reportsApi.createReport(idReport).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          context.commit("setSuccessAdd", true);
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          console.log("ERROR CREATE REPORT: ", error);
          //TODO notificación error report
          context.commit("setErrorAdd", true);
        }
      );
    },
  },
  getters: {
    getListReports: (state) => state.listReports,
    getReport: (state) => state.report,
    getTotalListReports: (state) => state.totalListReports,
    getSuccessAdd: (state) => state.successAdd,
    getErrorAdd: (state) => state.errorAdd,
    getShowModalAdd: (state) => state.showModalAdd,
  },
};
