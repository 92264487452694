import axios from 'axios';

axios.interceptors.request.use(function (config) {

    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
}, function (err) {
    return Promise.reject(err);
});
