import customersApi from "../../api/customers-api";

export default {
  namespaced: true,
  state: {
    currentCustomer: "",
    showModalCustomers: true,
    listCustomers: [],
    listCustomersFrecuentes: [],
  },
  mutations: {
    recoverCurrentCustomer(state) {
      const currentCustomer = JSON.parse(
        localStorage.getItem("currentCustomer")
      );
      if (!currentCustomer) {
        state.currentCustomer = null;
        state.showModalCustomers = true;
      } else {
        state.currentCustomer = currentCustomer;
        state.showModalCustomers = false;
      }
    },
    setCurrentCustomer(state, payload) {
      state.currentCustomer = payload;
      localStorage.setItem(
        "currentCustomer",
        JSON.stringify(state.currentCustomer)
      );
      this.commit("carts/removeLocalCart");
      location.reload();
    },
    setShowModalCustomers(state, payload) {
      state.showModalCustomers = payload;
    },
    setListCustomers(state, payload) {
      state.listCustomers = payload.data.todos;
      state.listCustomersFrecuentes = payload.data.frecuente;
    },
  },
  actions: {
    async searchCustomers(context, search) {
      const res = await customersApi.getListCustomers(search);
      context.commit("setListCustomers", res);
    },
    async searchCustomersAdmin(context, search) {
      const res = await customersApi.getListCustomersAdmin(search);
      context.commit("setListCustomers", res);
    },
    async getCustomerLogged(context, search) {
      const res = await customersApi.getCustomerLogged(search);
      var customer = {
        IDEMPRESA: res.data[0].CodCli,
        LOGIN: res.data[0].CodCli,
        NOMBRE: res.data[0].Cliente,
        NOMBREEMPRESA: res.data[0].Cliente,
        carrito: false,
      };
      localStorage.setItem("currentCustomer", JSON.stringify(customer));
      context.commit("setCurrentCustomer", customer);
    },
  },
  getters: {
    getCurrentCustomer: (state) => state.currentCustomer,
    getListCustomers: (state) => state.listCustomers,
    // getCustomerLogged: (state) => state.currentCustomer,
    getListCustomersFrecuentes: (state) => state.listCustomersFrecuentes,
    getShowModalCustomers: (state) => state.showModalCustomers,
  },
};
