import axios from "axios";
import { config } from "../shared/app.config";
import store from "@/store";

const RESOURCE_NAME = "/informes";
const RESOURCE_NAME_SINGULAR = "/informe";

export default {
  getListReports(limit, offset, sort) {
    const params = {
      limit,
      offset,
    };
    if (sort) {
      params.sort = sort;
    }
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/all`, { params });
  },

  getReport(idReport) {
    return axios.get(
      `${`${config.webApiBase + RESOURCE_NAME_SINGULAR}/${idReport}`}`
    );
  },

  createReport(idReport) {
    return axios.get(
      `${`${config.webApiBase + RESOURCE_NAME_SINGULAR}/generate/${idReport}`}`
    );
  },
  createFlujo() {
    return axios.get(
      `${`${config.webApiBase + RESOURCE_NAME_EDI}/flujo/todo`}`
    );
  },
};
