import axios from "axios";
import { config } from "../shared/app.config";
import store from "@/store";

const RESOURCE_NAME = "/products";

export default {
  getListProducts(search, limit, offset, sort, family, size) {
    const params = {
      name: search,
      limit,
      offset,
    };

    if (sort) {
      params.sort = sort;
    }
    if (family) {
      params.family = family;
    }
    if (size) {
      params.size = size;
    }

    const client = store.getters["customers/getCurrentCustomer"];

    const user = store.getters["auth/getUser"];

    console.log(client);

    if (user.rol_id === 3) {
      //Obtenemos los pedidos de ese usuario como cliente y "admin" como comercial
      return axios.get(
        `${config.webApiBase + RESOURCE_NAME}/customer/all/${user.usuario}`,
        { params }
      );
    } else if (user.rol_id === 2) {
      if (client != null) {
        return axios.get(
          `${config.webApiBase + RESOURCE_NAME}/customer/all/${
            client.IDEMPRESA
          }`,
          { params }
        );
      } else {
        return axios.get(`${config.webApiBase + RESOURCE_NAME}`, { params });
      }
    } else {
      return axios.get(`${config.webApiBase + RESOURCE_NAME}`, { params });
    }
  },
  getProduct(idProduct, comercial = 0) {
    const params = {
      comercial,
    };

    return axios.get(`${`${config.webApiBase + RESOURCE_NAME}/${idProduct}`}`, {
      params,
    });
  },
};
