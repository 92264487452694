import i18n from "@/libs/i18n";

export const config = {
  locales: [
    {
      locale: "es",
      img: require("@/assets/images/flags/es.svg"),
      name: "Español",
    },
    {
      locale: "en",
      img: require("@/assets/images/flags/en.svg"),
      name: "English",
    },
  ],
  estadosPedidos: {
    0: i18n.messages.Pendiente,
    1: i18n.messages.EnProceso,
    2: i18n.messages.EnProceso,
    3: i18n.messages.Tramitado,
  },
  colorPedidos: {
    0: "light",
    1: "light",
    2: "light",
    3: "light",
  },
  webApiBase: process.env.VUE_APP_ROOT_API,
};
